type Payload = { id: "scene"; name: string };

export class PScene {
  name!: string;

  constructor(payload: Payload) {
    this.patch(payload);
  }

  patch({ name }: Payload) {
    this.name = name;
  }
}
